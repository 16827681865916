import Carousel from 'react-bootstrap/Carousel';
import '../css/Galeria.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const Galeria = ({ listOfImages }) => {
  return (
    <Carousel
      prevIcon={
        <span className="carousel-control-p" aria-hidden="true">
          <FontAwesomeIcon icon={faChevronLeft} />
        </span>
      }
      nextIcon={
        <span className="carousel-control-n" aria-hidden="true">
          <FontAwesomeIcon icon={faChevronRight} />
        </span>
      }
    >
      {listOfImages.map((image, index) => (
        <Carousel.Item key={index}>
          <img src={image} alt={`Imagem ${index}`} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default Galeria;
