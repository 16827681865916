import React from 'react';

import './Contato.css'
import Header from '../../componentes/js/Header';
import Footer from '../../componentes/js/Footer';

const Contato = () => {
  return (
    <div>
      <Header />
      <div className="containerContato">
        <div className='tituloContato'>
          <h1 style={{ marginTop: 10 + 'px'}}>Contato</h1>
        </div>
        <article>
          <div className="map-container">
            <h2>Como Chegar</h2>
            <iframe 
              title='mapa'
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2271.856732514448!2d-49.2689922284222!3d-16.737208849927573!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935ef0beaeb330ad%3A0x36994ee1ea372394!2zQ29sw6lnaW8gTMOtZGVyIEdP!5e0!3m2!1spt-BR!2sbr!4v1718672574335!5m2!1spt-BR!2sbr"
              width="600" height="562" style={{ border: 0 }} allowFullScreen="" loading="lazy"
            ></iframe>
          </div>
        </article>
      </div>
      <Footer />
    </div>
  );
};

export default Contato;
