import React from "react";
import PropTypes from "prop-types";
import "../css/Youtube.css";

const YoutubeEmbed = ({ url }) => (
    <div className="video-container">
      <div className="video-responsive">
        <iframe
          src={url}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="YouTube"
        />
      </div>
    </div>
);

YoutubeEmbed.propTypes = {
    url: PropTypes.string.isRequired
};
  
export default YoutubeEmbed;
