import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../css/CarouselProfessores.css'

const Equipe = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <div className="conhecaEquipe">
            <h2>Conheça Nossa Equipe</h2>
            <Slider {...settings}>
                <div className="membroEquipe">
                    <h3>Blayth</h3>
                    <p>Diretor</p>
                    <div className='imagem'>
                        <img src='assets/images/coordenadores/blayth.png' alt='1'/>
                    </div>
                </div>
                <div className="membroEquipe">
                    <h3>Giselle</h3>
                    <p>Coordenadora</p>
                    <div className='imagem'>
                        <img src='assets/images/coordenadores/giselle.png' alt='1'/>
                    </div>
                </div>
                <div className="membroEquipe">
                    <h3>Mônica</h3>
                    <p>Coordenadora</p>
                    <div className='imagem'>
                        <img src='assets/images/coordenadores/monica.png' alt='1'/>
                    </div>
                </div>
            </Slider>
            <div className="botaoContainerProfessores">
                <a href="/nossa-equipe"><button className="botaoEquipe">Veja Mais</button></a>
            </div>
        </div>
    );
}

export default Equipe;
