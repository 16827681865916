import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import Inicio from './Inicio';
import Jornal from './paginas/Jornal/Jornal';
import Contato from './paginas/Contato/Contato';
import Professores from './paginas/NossosProfessores/Equipe';
import Tour360 from './paginas/Tour/Tour';
import EnsinoFundamental1 from './paginas/Ensino/Fundamental/1/EnsinoFundamental1';
import EnsinoFundamental2 from './paginas/Ensino/Fundamental/2/EnsinoFundamental2';
import EnsinoMedio from './paginas/Ensino/Medio/EnsinoMedio';
import Erro from './paginas/404/Erro';

import WhatsAppButton from './componentes/js/Whatsapp';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <WhatsAppButton zapURL={'https://api.whatsapp.com/send?phone=5562998271550&text=Bom%20dia!'}/>
    <Router>
      <Routes>
        <Route path='/404' element={<Erro />} />
        <Route path='/inicio' element={<Inicio />} />
        <Route path='/jornal' element={<Jornal />} />
        <Route path='/contato' element={<Contato />} />
        <Route path='/ensino-fundamental-1' element={<EnsinoFundamental1 />} />
        <Route path='/ensino-fundamental-2' element={<EnsinoFundamental2 />} />
        <Route path='/ensino-medio' element={<EnsinoMedio />} />
        <Route path='/nossa-equipe' element={<Professores />} />
        <Route path='/tour' element={<Tour360 />} />
        <Route path='/' element={<Navigate to='/inicio' />} />
        <Route path='*' element={<Navigate to='/404' />} />
      </Routes>
    </Router>
  </React.StrictMode>
);