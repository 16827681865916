import React from 'react';

import './Jornal.css'
import Header from '../../componentes/js/Header';
import Footer from '../../componentes/js/Footer';

const Jornal = () => {
  return (
    <div>
      <Header />
      <div className="containerJornal">
        <div className='tituloContato'>
          <h1 style={{ marginTop: 10 + 'px'}}>LíderPress</h1>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Jornal;
