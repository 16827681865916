import React, { useState } from 'react';
import Header from '../../componentes/js/Header';
import Footer from '../../componentes/js/Footer';
import './Equipe.css';

const teamFolder = 'assets/images/professores/';

const imageCount = 20; // Quantidade de imagens
const imageArray = Array.from({ length: imageCount }, (_, index) => `${teamFolder}${index + 1}.png`);

const Equipe = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const openLightbox = (image) => {
    setSelectedImage(image);
  };

  const closeLightbox = () => {
    setSelectedImage(null);
  };

  return (
    <div>
      <Header />
      <div className="team-section">
        <h2>Conheça Nosso Time</h2>
        <div className="team-grid">
          {imageArray.map((image, index) => (
            <div key={index} className="team-member" onClick={() => openLightbox(image)}>
              <img src={image} alt={`Professor ${index + 1}`} className="professor-image" />
            </div>
          ))}
        </div>
      </div>
      {selectedImage && (
        <div className="lightbox" onClick={closeLightbox}>
          <img src={selectedImage} alt="Professor" className="lightbox-image" />
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Equipe;