// src/components/Modal.js
import React from 'react';
import '../css/Modal.css';

function Modal({ show, onClose, title, text, imageUrl, videoUrl }) {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>X</button>
        <div className="modal-body">
          <div className="modal-text">
            <h3>{title}</h3>
            <p>{text}</p>
          </div>
          <div className="modal-media">
            {imageUrl && (
              <div className="modal-image">
                <img src={imageUrl} alt={title} />
              </div>
            )}
            {videoUrl && (
              <div className="modal-video">
                <iframe 
                    width="340vw" 
                    height="200vh" 
                    src={videoUrl} 
                    title={title} 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    referrerpolicy="strict-origin-when-cross-origin" 
                    allowfullscreen>
                </iframe>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;