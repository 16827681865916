import React from 'react'
import Header from '../../componentes/js/Header'
import Footer from '../../componentes/js/Footer'

import './Erro.css'

const Erro = () => {
  return (
    <div>
        <Header />
        <div className='container'>
            <h1>404 - Erro</h1>
            <h2>Página não encontrada</h2>
        </div>
        <Footer />
    </div>
  )
}

export default Erro
