import './Inicio.css';
import YoutubeEmbed from './componentes/js/Youtube';
import Header from './componentes/js/Header';
import Footer from './componentes/js/Footer';
import Galeria from './componentes/js/Galeria';
import Equipe from './componentes/js/CarouselProfessores';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faTiktok, faLinkedin} from "@fortawesome/free-brands-svg-icons";

function Inicio() {
  function importAll(r) {
    return r.keys().map(r);
  }

  const listOfImages = importAll(
    require.context(
      "./paginas/galeria",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );

  return (
    <div>
      <Header />

      <div className='fundoInicio'>
        <div className="onda onda-top"></div>
        <Galeria listOfImages={listOfImages} />
      </div>

      <div className="redirecionamento-container">
        <div className="imagem-container">
          <div className="botoes">
            <span className="botao">
              <a href="ensino-fundamental-1">
                <img src="assets/images/banners/fundamental.png" alt="Ensino Fundamental"/>
              </a>
            </span>
            <span className="botao">
              <a href="ensino-fundamental-2">
                <img src="assets/images/banners/fundamental2.png" alt="Ensino Fundamental"/>
              </a>
            </span>
            <span className="botao medio">
              <a href="ensino-medio">
                <img src="assets/images/banners/medio.png" alt="Ensino Médio"/>
              </a>
            </span>
          </div>
        </div>
      </div>

      <div class="alunoOnline">
          <div class="centralAlunoContainer">
              <img src="assets/images/estudantes/estudante_central_alunos.png" alt="Aluno" class="imagemAluno" />
              <div class="botaoContainer">
                  <a href="https://aluno.escolarmanageronline.com.br/colegiolidergo"><button class="centralAluno">ACESSAR CENTRAL DO ALUNO</button></a>
                  <p class="subTitulo">Não é aluno? <a href='contato'>Matricule-se!</a></p>
              </div>
          </div>
      </div>

      <div className="sobrenos-container">
        <div className="fundo">
          <h1>Apresentação do nosso colégio</h1>
        </div>
        <div className="video">
          <YoutubeEmbed url="https://www.youtube.com/embed/isuSIrnQHCI?vq=hd720p" />
        </div>
      </div>

      <Equipe />
    
      <div className="grid-container">
        <div className="grid-item">
          <div className="texto">
            <h1>Proposta Pedagógica</h1>
            <hr className="amarelo"/>
            <p>
              O Colégio Líder oferece o Ensino Fundamental e o Ensino Médio, 
              apoiado no desenvolvimento de uma consciência crítica, na autonomia, 
              responsabilidade e criatividade para uma boa execução do processo ensino-aprendizagem, 
              e ainda, busca dar direcionamento à prática educativa, visando à formação integral 
              dos alunos, em seus aspectos físicos, intelectuais e emocionais.
            </p>
          </div>
          <div className="imagem">
            <img src="assets/images/proposta-pedagogica.jpg" alt="Proposta Pedagógica"/>
          </div>
        </div>
        <div className="grid-item alternado">
          <div className="texto">
            <h1>O Colégio</h1>
            <hr className="amarelo"/>
            <p id='textoAlternado'>
              O Colégio Líder é mais do que uma escola, é um projeto de vida para os 
              seus alunos. Aqui, nós oferecemos uma educação de excelência, baseada em valores 
              éticos, humanos e sociais, que contribuem para a formação integral dos nossos 
              estudantes. Nossos alunos do Ensino Fundamental e do Ensino Médio são estimulados a 
              descobrir seus talentos, suas paixões, suas vocações e suas missões. Eles aprendem a 
              pensar criticamente, a agir com autonomia, a assumir responsabilidades e a criar soluções 
              inovadoras. Nossa equipe pedagógica é formada por profissionais competentes e dedicados, 
              que acompanham de perto o desenvolvimento de cada aluno, respeitando suas individualidades 
              e potencializando suas capacidades. Venha conhecer o Colégio Líder, uma escola que transforma vidas!
            </p>
          </div>
        </div>
      </div>
      <div class="lider-container">
        <div class="content">
          <h2>Líder Connect</h2>
          <p>
            O app do colégio líder é uma ferramenta incrível para os alunos e os pais. 
            Com ele, você pode consultar suas notas, ver as direções do dia a dia, 
            receber comunicados importantes e muito mais. Os pais também podem acompanhar o 
            consumo dos filhos na cantina, saber o que eles estão comendo e quanto estão gastando. 
            O app do colégio líder é o melhor jeito de ficar por dentro de tudo que acontece na escola. Baixe já e aproveite!
          </p>
          <div className='botao'>
            <a 
              href="https://play.google.com/store/apps/details?id=br.com.terabytesolucoes.terasmart.colegiolidergo&hl=pt_BR&gl=US"
              target="_blank"
              rel="noreferrer"
              >
              <button>Baixar APP</button>
            </a>
          </div>
          <div className="social-icons">
            <div className="icon">
                <a href='https://www.facebook.com/colegiolidergo' target='_blank' rel='noreferrer'>
                    <FontAwesomeIcon icon={faFacebookF} />
                </a>
            </div>
            <div className="icon">
                <a href='https://www.instagram.com/colegiolidergo/' target='_blank' rel='noreferrer'>
                    <FontAwesomeIcon icon={faInstagram} />
                </a>
            </div>
            <div className="icon">
                <a href='t' target='_blank' rel='noreferrer'>
                    <FontAwesomeIcon icon={faTiktok} />
                </a>
            </div>
            <div className="icon">
                <a href='t' target='_blank' rel='noreferrer'>
                    <FontAwesomeIcon icon={faLinkedin} />
                </a>
            </div>
        </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Inicio;
