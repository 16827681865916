// src/components/Tour360.js
import React from 'react';
function Tour360() {
  return (
    <div className="tour-container">
      <iframe 
        src="https://colegiolidergo.com.br/tour360/" 
        title="Tour 360 do Colégio Líder"
        width="100%"
        height="600px"
        style={{ border: 'none' }}
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default Tour360;
