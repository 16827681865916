import React from 'react';
import '../css/Whatsapp.css'


const WhatsAppButton = ({ zapURL }) => {
    return (
        <div className="whatsapp-container">
            <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.3.1/css/all.css" integrity="sha384-mzrmE5qonljUremFsqc01SB46JvROS7bZs3IO2EmfFsd15uHvIt+Y8vEf7N7fWAU" crossorigin="anonymous" />
            <a href={zapURL} target="_blank" rel="noopener noreferrer" className="whatsapp-link">
                <i class="fab fa-whatsapp" aria-hidden="true" />
                <span id="texto">Fale conosco!</span>
            </a>
        </div>
    );
}

export default WhatsAppButton;
